
import { defineComponent } from "vue";
import Button from "primevue/button";
import * as FacebookServices from "../api/helpers/Facebook";


export default defineComponent({
  name: "Privacy",
  components: {
    Button,
  },
  data() {
    return {
      name: process.env.VUE_APP_TITLE,
    };
  },
  mounted() {
    FacebookServices.pageView();
    document.title = process.env.VUE_APP_TITLE + " | Aviso de privacidad";
    window.scrollTo(0, 0);
  },
});
